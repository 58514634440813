// Dependencies
import React, { useState, useEffect, useMemo } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
// Hooks
import useMedia from "../hooks/useMedia"
import useMount from "../hooks/useMount"
import useUpdateHeader from "../hooks/useUpdateHeader"
// Components
import { BackgroundImage } from "../components/Image"

const BackgroundVideo = ({ video }) => {

	return (
		<div className="object-cover pabs fs">
			<video src={video?.mp4?.file?.url} loop muted autoPlay playsInline />
		</div>
	)
}

// --------------------
// Had to break these into two distinct components, as updating the image source data triggered some bugginess
// Pretty sure we need to have unique keys here as well which we are picking up from the asset
const LandscapeImage = ({ id, landscape, portrait }) => {
	const image = landscape || portrait
	return (
		image && (
			<BackgroundImage className="bg-image pabs fs" image={image.gatsbyImageData} alt={'this'} key={id} />
		)
	)
}
const PortraitImage = ({ id, portrait, landscape }) => {
	const image = portrait || landscape
	return (
		image && (
			<BackgroundImage className="bg-image pabs fs" image={image.gatsbyImageData} alt={'this'} key={id} />
		)
	)
}

function useVisual(visuals) {
	const ready = useMount()
	const visual = useMemo(() => {
		return visuals && visuals[Math.floor(Math.random() * visuals.length)]
	}, [])
	return [ready, visual]
}

const SplashImage = ({ 
	className = "splash-image", 
	visuals, 
	showLogo,
	debug,
	children 
}) => {


	const isDevicePortrait = useMedia(
    // Media queries
    ['(orientation: landscape)'],
    // Column counts (relates to above media queries by array index)
    [false],
    // Default column count
    true
	)

	// Content
	const [ready, currentVisual] = useVisual(visuals)
	// const test = useVisual(visuals)
	const color = (isDevicePortrait && currentVisual?.portrait) ? currentVisual?.portraitColor : currentVisual?.landscapeColor

	// UX
	const [ref] = useUpdateHeader(color === 'white' ? 'var(--white)' : 'var(--black)', showLogo)

  // if (debug) {
	// 	console.log(currentVisual)
	// }

	return (
		ready && (
			<div className={`${className} color--${color}`} ref={ref}>

				{currentVisual.backgroundVideo ? (
					<BackgroundVideo video={currentVisual.backgroundVideo} />
				) : isDevicePortrait ? (
					currentVisual.portrait ? (
						<PortraitImage {...currentVisual} />
					) : (
						<LandscapeImage {...currentVisual} />
					)
				) : (
					<LandscapeImage {...currentVisual} />
				)}
				
				{children && (
					<div className="z-body">
						{children}
					</div>
				)}
			</div>
		)	
	)
}

SplashImage.propTypes = {
  id: PropTypes.string
}

export default SplashImage

export const SplashImageFragment = graphql`
	fragment SplashImageFragment on ContentfulSplashImage {
		id
		title
		landscape {
			id
			file {
				contentType
				url
			}
			gatsbyImageData(
				layout: FULL_WIDTH
				quality: 80
				# formats: JPG
			)
		}
		landscapeColor: foregroundColor # todo rename field id
		portrait {
			id
			file {
				contentType
				url
			}
			gatsbyImageData(
				layout: FULL_WIDTH
				quality: 80
				# formats: JPG
			)
		}
		portraitColor
	}
`