// Dependencies
import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
// Hooks
import useMedia from "../hooks/useMedia"
// Components
import Layout from "../components/Layout"
import SplashImage from "../components/SplashImage"
import { BackgroundImage } from "../components/Image"
import { Arrow } from "../components/Icons"

const Homepage = ({ data }) => {

	const content = data.allContentfulHomepage.nodes[0];

	return (
		<Layout dontHideLogo hasSplash hideContactBar triggerResizeOnce>
			<div className="homepage">

				{/* Landing */}
				<SplashImage className="homepage-section fv" visuals={content.heroVisuals} showLogo debug>
					<h1 className="h0 adjust white-overlay hero-tagline">{content.heroTagline.heroTagline}</h1>
				</SplashImage>
				
				{/* Homepage links */}
				{content.sections.map((entry) => (
					<SplashImage className="homepage-section fv" {...entry} key={entry.id} showLogo>
						<div className="align-center p3--summary text-shadow">
							<Link to={`/${entry.link.slug}`} className="h1--homepage-section" style={{ color: entry.link.colorHighlight }}>
								{entry.title}
							</Link>
							<p className="outer summary">
								<span className="text-shadow">{entry.description.copy}</span>
								<strong>
									<Link to={`/${entry.link.slug}`} className="has-arrow inlb gutters" style={{ color: entry.link.colorHighlight }}>{` View`} <Arrow /></Link>
								</strong>
							</p>	
						</div>
					</SplashImage>
				))}
			</div>
		</Layout>
	)
}

Homepage.propTypes = {
  data: PropTypes.object.isRequired
}

export default Homepage

export const HomepagePageQuery = graphql`
  query HomepageQuery {
		allContentfulHomepage {
			nodes {
				heroVisuals {
					...SplashImageFragment
					backgroundVideo {
						# vimeoUrl
						mp4 {
							file {
								url
							}
						}
					}
				}
				heroTagline {
					heroTagline
				}
				sections {
					id
					title
					description {
						copy: description
					}
					link {
						slug
						colorHighlight
					}
					visuals {
						...SplashImageFragment
						backgroundVideo {
							# vimeoUrl
							mp4 {
								file {
									url
								}
							}
						}
					}
				}
			}
		}
  }
`